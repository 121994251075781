<template>
  <div class="transfer-warranty-stock">
    <KTCodePreview v-bind:title="'Thông tin phiếu chuyển kho bảo hành'">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-col cols="6">
            <b-row>
              <b-form-group class="col-6">
                <label>Ngày chuyển kho:</label>
                <b-form-input
                  tabindex="1"
                  v-model="transferDate"
                  type="text"
                  size="sm"
                  :disabled="true"
                >
                </b-form-input>
              </b-form-group>
            </b-row>

            <b-row>
              <b-form-group class="col-6 required-control">
                <label>Kho nguồn:</label>
                <vue-autosuggest
                  class="border-radius-none mt-2"
                  aria-describedby="input-store-live-feedback"
                  :suggestions="filteredOptions"
                  :limit="200"
                  :input-props="inputSearchSourceWarehouse"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchSourceStore"
                  :disabled="true"
                >
                </vue-autosuggest>
              </b-form-group>

              <b-form-group class="col-6 required-control">
                <label>Kho đích:</label>
                <vue-autosuggest
                  class="border-radius-none mt-2"
                  aria-describedby="input-store-live-feedback"
                  :suggestions="filteredOptions"
                  :input-props="{
                    class: 'autosuggest__input',
                    placeholder: 'Chọn kho đích',
                    style: 'border-radius:0px!important',
                    disabled: true,
                  }"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchDesStore"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-12">
                <template>
                  <span>Nội dung:</span>
                </template>
                <b-form-textarea
                  size="sm"
                  v-model="notes"
                  :placeholder="'Thêm nội dung...'"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-row>
          </b-col>
        </b-row>

        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          ">
          <thead>
            <tr>
              <th
                scope="col"
                class="title-center"
              >Tên sản phẩm</th>
              <th
                scope="col"
                class="title-center"
              >IMEI</th>
              <th
                scope="col"
                class="title-center"
              >Số lượng</th>
              <th
                scope="col"
                class="title-center"
              >
                Đơn giá
              </th>
              <th
                scope="col"
                class="title-center"
              >
                Thành tiền
              </th>
            </tr>
          </thead>
          <tbody
            v-for="item in warrantyItems"
            :key="item.id"
          >
            <TransferWarrantyStockItem
              v-bind:productItem="item"
              v-bind:editable="true"
            />
          </tbody>
        </table>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit()"
          v-if="status === 1"
        >Duyệt</b-button>
        <router-link
          to="/require-warranty-stocks"
          tag="button"
          v-if="status === 1"
        >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
          >Hủy</b-button>
        </router-link>
        <router-link
          to="/require-warranty-stocks"
          tag="button"
          v-if="status !== 1"
        >
          <b-button
            style="font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
          >Hủy</b-button>
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '@/utils/saveDataToLocal';
import { convertPrice } from '@/utils/common';
import ApiService from '../../../core/services/api.service';
import Swal from 'sweetalert2';
import TransferWarrantyStockItem from '../../components/TransferWarrantyStockItem.vue';
import moment from 'moment';
import {
  makeToastFaile,
  unMaskPrice,
  makeToastSuccess,
} from '../../../utils/common';
import { TIME_TRIGGER } from '../../../utils/constants';
import { STOCK_SLIP_TYPE } from '../../../utils/enum';

export default {
  components: {
    KTCodePreview,
    TransferWarrantyStockItem,
  },
  data() {
    return {
      createBy: '',
      status: null,
      stockId: null,
      warrantyItems: [],
      totalPrice: 0,
      totalQuantity: 0,
      notes: '',
      transferDate: null,
      filteredOptions: [],
      listInventories: [],
      options: [],
      searchSourceStore: '',
      sourceStoreId: null,
      searchDesStore: '',
      sourceStoreSelected: null,
      desStoreSelected: null,
      codeStockBill: null,
      errors: [],
      isNew: true,
      inputSearchProductProp: {
        class: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      inputSearchSourceWarehouse: {
        class: 'autosuggest__input',
        placeholder: 'Chọn kho nguồn',
        style: 'border-radius:0px!important',
        disabled: true,
      },
    };
  },
  created() {
    this.transferDate = moment(new Date()).format('DD/MM/yyyy');
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu chuyển kho bảo hành', route: '/require-warranty-stocks' },
      { title: 'Duyệt phiếu chuyển kho bảo hành' },
    ]);
    this.stockId = this.$route.query.id;
    if (this.stockId) {
      this.fetchStockById();
    }
  },
  methods: {
    convertPrice,
    onSubmit() {
      const listPro = this.warrantyItems.map((element) => {
        return {
          id: element.id,
          productId: element.proId,
          imeis: element.imeiCode.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.productPrice),
        };
      });

      const data = {
        id: this.stockId,
        listProduct: listPro,
        destinationWarehouse: this.desStoreSelected,
        requireStockSlipType: STOCK_SLIP_TYPE.REQUIRE_EXPORT_WARRANTY_STOCK,
        description: this.notes,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.put('stock-slips/approve-transfer', data)
          .then(({ data }) => {
            if (data.status === 1) {
              makeToastSuccess(data.message);
              this.$router.push({
                name: 'require-warranty-stocks',
              });
            } else {
              makeToastFaile(data.message);
              setTimeout(() => {
                this.isNew = true;
              }, TIME_TRIGGER);
            }
          })
          .catch(({ response }) => {
            const { status, message } = response.data;
            if (status === 0) {
              makeToastFaile(message);
              this.isNew = true;
            }
          });
      }
    },
    fetchStockById: async function () {
      ApiService.setHeader();
      await ApiService.get(`stocks/transfer-stock/${this.stockId}`).then(
        ({ data }) => {
          let dataRes = data.data;
          this.codeStockBill = dataRes.code;
          this.dateCreate = moment(String(dataRes.createdAt)).format(
            'HH:mm DD/MM/YYYY',
          );
          this.transferDate = moment(String(dataRes.transferDate)).format(
            'DD-MM-YYYY',
          );
          this.sourceStoreSelected = dataRes.sourceStoreId;
          this.desStoreSelected = dataRes.desStoreId;
          this.totalPrice = dataRes.totalAmount;
          this.notes = dataRes.description;
          this.createBy = dataRes.createdBy;
          this.status = dataRes.status;
          //   this.idStock = dataRes.id;

          dataRes.listDetail.forEach((element) => {
            this.count++;
            let dataItem = {
              id: element.id,
              productName: element.productName,
              imeiCode: element.productImei.replace(', ', '\n'),
              proId: element.productId,
              productPrice: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
              productType: element.productType,
              quantityRequire: element.quantity,
              barCode: element.barCode,
              productCode: element.productCode,
            };
            this.warrantyItems.push(dataItem);
          });
        },
      );

      await this.fetchStore();
    },
    showWarningAlert: function () {
      Swal.fire({
        title: 'Lưu và duyệt phiếu chuyển kho!',
        text: 'Bạn có chắc muốn lưu và duyệt phiếu chuyển kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Có',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.createStock('create-approve-stock-slip');
        }
      });
    },
    fetchStore: async function () {
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          if (element.id === this.sourceStoreSelected) {
            this.searchSourceStore = element.name;
          }
          if (element.id === this.desStoreSelected) {
            this.searchDesStore = element.name;
          }
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options.push(store);
        });
        let tmp = [{ data: this.options }];
        this.filteredOptions = tmp;
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>

<style lang="scss">
.transfer-warranty-stock {
  .title-center {
    text-align: center;
  }
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
</style>
